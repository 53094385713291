exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-pt-tsx": () => import("./../../../src/pages/index.pt.tsx" /* webpackChunkName: "component---src-pages-index-pt-tsx" */),
  "component---src-pages-privacy-en-tsx": () => import("./../../../src/pages/privacy.en.tsx" /* webpackChunkName: "component---src-pages-privacy-en-tsx" */),
  "component---src-pages-privacy-pt-tsx": () => import("./../../../src/pages/privacy.pt.tsx" /* webpackChunkName: "component---src-pages-privacy-pt-tsx" */),
  "component---src-pages-terms-en-tsx": () => import("./../../../src/pages/terms.en.tsx" /* webpackChunkName: "component---src-pages-terms-en-tsx" */),
  "component---src-pages-terms-pt-tsx": () => import("./../../../src/pages/terms.pt.tsx" /* webpackChunkName: "component---src-pages-terms-pt-tsx" */)
}

